select#discount {
    width: 50px;
    padding-right: 15px;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: bold;
}

.select {
    width: auto !important;
}

#discount > option {
    color: #000;
    font-weight: bold;
}

.chakra-select__icon-wrapper.css-fmsrp {
    right: 5px;
}

.DayPickerInput {
    margin: 0;
    height: 40px;
    text-align: right;
}

.DayPickerInput input {
    background: #fefefe;
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    text-align: right;
    padding-right: 1rem;
}

.DayPickerInput-OverlayWrapper {
    position: fixed;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    padding-top: 20px;
    background: white;
    z-index: 2;
    width: 600px;
    height: 330px;
    border-radius: 5px;
}

.DayPickerInput-Overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.DayPicker {
    width: 100%;
    height: 100%;
}

.DayPicker-wrapper {
    height: 100%;
    padding: 0;
    font-size: 16px;
}

.DayPicker-NavButton {
    width: 2em;
    height: 2em;
}

.DayPicker-NavButton.DayPicker-NavButton--prev {
    background: url("../../illustrations/left.svg") no-repeat;
    left: 1.5em;
    margin-right: 0;
}

.DayPicker-NavButton.DayPicker-NavButton--next {
    background: url("../../illustrations/right.svg") no-repeat;
}

.DayPicker-Month {
    width: 100%;
    height: 100%;
    color: #36495E;
}

.DayPicker-Caption > div {
    text-align: center;
}

.DayPicker-Weekday {
    color: #36495E;
    font-size: 1em;
}

.DayPicker-Body {
    color: #007abd;
    font-weight: normal;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: transparent;
    color: #00A7DF;
    font-weight: bold;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    color: #fff;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #0091f7;
    color: #fff;
}

.DayPicker-Day--today {
    border-radius: 50%;
    font-weight: normal;
}