.currencies {
    width: auto !important;
    height: 100% !important;
}

.currencies select {
    height: 100%;
    font-size: 20px;
}

.currencies .chakra-select__wrapper {
    height: 100%;
}

.currencies option {
    color: #000;
}

.chakra-select__icon-wrapper.css-fmsrp {
    right: 5px;
}